<template>
  <div>
    <v-container fluid>
      <v-card class="shadow rounded-lg">

        <Overlay :overlay="overlay"/>

        <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
          <v-col cols="12" lg="3" order="1" order-lg="0">
            <v-text-field v-model.trim="keyword"
                          class="rounded-input"
                          clearable
                          dense
                          hide-details
                          outlined
                          placeholder="Recherche..."
                          prepend-inner-icon="mdi-magnify"
                          rounded
                          single-line/>
          </v-col>

          <v-col class="text-end" cols="12" lg="9">

            <v-btn v-if="Object.keys(filter).length"
                   color="red"
                   text @click="[filter = {},getDatabase()]">
              <v-icon left>mdi-filter-off</v-icon>
              Filtre
            </v-btn>

            <v-btn color="primary" text @click="$refs.filterDatabaseDialog.open()">
              <v-icon left>mdi-filter</v-icon>
              Filtre
            </v-btn>

            <v-btn class="mx-2" color="primary" depressed @click="$refs.importDialog.open()">
              <v-icon left>mdi-import</v-icon>
              Importer
            </v-btn>

            <v-btn color="primary" text @click="$refs.salepointForm.open()">
              <v-icon left>mdi-plus</v-icon>
              Ajouter
            </v-btn>

            <v-btn color="primary" text @click="$func.export('database/export-excel')">
              <v-icon left>mdi-microsoft-excel</v-icon>
              Expotrer
            </v-btn>

            <v-btn color="primary"
                   icon @click="getDatabase">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>

          </v-col>
        </v-row>


        <v-divider/>

        <v-card-text>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>
            <div v-if="salepoints.length">
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Code</th>
                    <th>Magasin</th>
                    <th>N°Téléphone</th>
                    <th>Type</th>
                    <th>Wilaya</th>
                    <th class="text-center">Compte Gifty</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in salepoints" :key="index">
                    <td>
                      <div class="d-flex text-no-wrap align-center">

                        <v-avatar v-if="item.image" size="38">
                          <v-img :src="$baseUrl + item.image"
                                 class="zoom-pointer"
                                 @click="$refs.lightbox.open(item.image)"></v-img>
                        </v-avatar>

                        <v-avatar v-else
                                  :class="$func.randomColor()"
                                  class="font-weight-medium"
                                  size="38">
                          {{ $func.avatarName(item.name) }}
                        </v-avatar>

                        <span class="ml-2">{{ item.name }}</span>
                      </div>
                    </td>
                    <td>
                      <span class="font-weight-medium orange--text">{{ item.code }}</span>
                    </td>
                    <td>{{ item.store_name }}</td>
                    <td>
                      {{ item.phone }}
                    </td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.city }}</td>

                    <td class="text-center">
                      <v-icon v-if="item.has_gifty_account" color="secondary" size="20">mdi-check-circle</v-icon>
                      <v-icon v-else color="red" size="20">mdi-close-circle</v-icon>
                    </td>

                    <td>
                      <div class="d-flex">
                        <v-btn color="" icon @click="$refs.salepointForm.open(item)">
                          <v-icon size="20">mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn color="red" icon @click="deleteSalepoint(item.id,index)" disabled>
                          <v-icon size="20">mdi-trash-can</v-icon>
                        </v-btn>
                        <v-btn color="" icon>
                          <v-icon size="20">mdi-eye-plus-outline</v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card-actions v-if="total > 10">

                <div class="d-flex align-center">
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                  &nbsp;
                  {{ salepoints.length }} sur {{ total }}
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"

                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
            <NoResult v-else/>
          </div>

        </v-card-text>
      </v-card>


      <Lightbox ref="lightbox"/>
      <ConfirmDialog ref="confirmDialog"/>
      <FilterDatabaseDialog ref="filterDatabaseDialog"
                            :has-gifty="true"
                            @search="[pagination.current = 1,filter = $event,getDatabase()]"/>
      <ImportDialog ref="importDialog" @refresh="getDatabase"/>
      <SalepointForm ref="salepointForm" :types="types" @refresh="getDatabase"/>
      <SalepointDetailDialog ref="salepointDetailDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import ImportDialog from "@/views/database/components/ImportDialog.vue";
import SalepointForm from "@/views/database/components/SalepointForm.vue";
import FilterDatabaseDialog from "@/views/database/components/FilterDatabaseDialog.vue";
import SalepointDetailDialog from "@/views/database/components/SalepointDetailDialog.vue";

export default {
    components: {ImportDialog, SalepointForm, FilterDatabaseDialog, SalepointDetailDialog},
    data() {
        return {
            typeLoading: false,
            types: [],

            salepoints: [],
            overlay: false,
            isLoading: false,

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {}
        }
    },

    methods: {
        getDatabase() {
            this.isLoading = true
            HTTP.get('/database?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.salepoints = res.data.data.data

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

                this.getSalepointTypes()

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        async deleteSalepoint(id, index) {
            if (await this.$refs.confirmDialog.open()) {
                this.overlay = true
                HTTP.delete('database/' + id + '/delete').then(() => {
                    this.salepoints.splice(index, 1)
                    this.overlay = false
                    this.$successMessage = 'Cet élément a été supprimé avec succès.'
                }).catch(err => {
                    this.overlay = false
                    console.log(err)
                })
            }
        },
        onPageChange() {
            this.getDatabase();
        },
        getSalepointTypes() {
            this.typeLoading = true
            HTTP.get('database/salepoint-types').then(res => {
                this.types = res.data.data
                this.typeLoading = false
            }).catch(err => {
                this.typeLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getDatabase()
    },
    watch: {
        keyword() {
            this.getDatabase();
        },
        per_page() {
            this.pagination.current = 1
            this.getDatabase();
        },
    },
}
</script>

<style scoped>

</style>