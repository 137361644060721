<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-text-field v-model="form.code"
                        :error-messages="errors.code"
                        dense
                        label="Code *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.name"
                        :error-messages="errors.name"
                        dense
                        label="Nom *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.store_name"
                        :error-messages="errors.store_name"
                        dense
                        label="Nom de magasin *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.phone"
                        :error-messages="errors.phone"
                        dense
                        label="Numéro de téléphone *"
                        outlined>
          </v-text-field>

          <v-combobox v-model="form.type"
                    :error-messages="errors.type"
                    :items="types"
                    dense
                    label="Type de pos *"
                    outlined>
          </v-combobox>

          <v-autocomplete v-model="form.city"
                          :error-messages="errors.city"
                          :items="cities"
                          :loading="isLoading"
                          dense
                          item-text="name"
                          item-value="name"
                          label="Wilaya *"
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="form.region"
                          :disabled="!form.city"
                          :error-messages="errors.region"
                          :items="regions"
                          :loading="isLoading"
                          dense
                          item-text="name"
                          item-value="name"
                          label="Région *"
                          outlined>
          </v-autocomplete>


          <v-autocomplete v-model="form.municipality"
                          :disabled="!form.region"
                          :error-messages="errors.municipality"
                          :items="municipalities"
                          :loading="isLoading"
                          dense
                          item-text="name"
                          item-value="name"
                          label="Commune *"
                          outlined>
          </v-autocomplete>

          <v-text-field v-model="form.address"
                        :error-messages="errors.address"
                        dense
                        label="Adresse"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.landmark"
                        :error-messages="errors.landmark"
                        dense
                        label="Repère"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.latitude"
                        :error-messages="errors.latitude"
                        dense
                        label="Latitude"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.longitude"
                        :error-messages="errors.longitude"
                        dense
                        label="Longitude"
                        outlined>
          </v-text-field>

          <v-file-input ref="image"
                        :error-messages="errors.image"
                        accept="image/*"
                        chips
                        counter
                        dense
                        label="Image"
                        outlined
                        show-size
                        small-chips
                        @change="uploadImage"
                        @click:clear="resetImage">
          </v-file-input>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['types'],
    data() {
        return {
            isLoading: false,
            cities: [],
            regions: [],
            municipalities: [],

            title: '',
            dialog: false,

            saveLoading: false,
            errors: {},
            form: {},
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item) {
            this.resetImage()
            if (!this.cities.length || !this.regions.length || !this.municipalities.length) {
                this.getCities()
            }
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                code: item ? item.code : '',
                name: item ? item.name : '',
                store_name: item ? item.store_name : '',
                phone: item ? item.phone : '',
                address: item ? item.address : '',
                latitude: item ? item.latitude : '0.00',
                longitude: item ? item.longitude : '0.00',
                image: '',

                city: item ? item.city : '',
                region: item ? item.region : '',
                municipality: item ? item.municipality : '',
                landmark: item ? item.landmark : '',
                type: item ? item.type : '',
            }
            this.title = item ? 'Modifier un pos' : 'Ajouter un pos'
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/database/' + this.form.id + '/update' : '/database/store',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                if (this.form.id) {
                    this.dialog = false
                }
                this.$emit('refresh')
                this.$successMessage = 'Cet élément a ete enregistré avec succes'
                this.saveLoading = false
                this.resetImage()

                this.form = {
                    id: '',
                    code: '',
                    name: '',
                    store_name: '',
                    phone: '',
                    address: '',
                    latitude: '',
                    longitude: '',
                    image: '',
                    city: '',
                    region: '',
                    municipality: '',
                    landmark: '',
                    type: '',
                }

            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        },
        getCities() {
            this.isLoading = true
            HTTP.get('database/cities').then(res => {
                this.cities = res.data.data.cities
                this.regions = res.data.data.regions
                this.municipalities = res.data.data.municipalities
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>